import React, { useEffect } from "react";
import Layout from "../components/layout/layout";
import { container } from "../styles/legal.module.scss";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div className={container}>
        <h2>Términos y condiciones</h2>

        <h6>I. ¿Quiénes somos?</h6>
        <p>
          Petki Labs S.L <br />
          CIF: B02878866 <br />
          Domicilio social: c/ Ramon Escayola 20, Sant Cugat del Vallés, 08197
          <br />
          Inscrita en el Registro Mercantil de Barcelona al Tomo 47565, folio
          173, hoja número B-555765
          <br />
          E-mail: team@kolyy.com
          <br />
          Telf.: +34 647 82 63 69
          <br />
          <br />
          En adelante, se hará referencia a Petki Labs S.L. como «Kolyy»
        </p>
        <h6>II. ¿Qué ofrecemos?</h6>
        <p>
          Kolyy ofrece: <br />
          <br />
          (i) «Collar Inteligente»: Collar inteligente para mascotas que
          incorpora un localizador GPS radioeléctrico de Kolyy, el cual está
          ajustado a los servicios que se ofrecen bajo Suscripción. Para que las
          funcionalidades del collar sean operativas, el Cliente deberá
          contratar Suscripción de pago. <br /> <br />
          (ii) «Accesorios»: accesorios Kolyy relacionados con el Collar
          Inteligente. <br /> <br />
          (iii) «Suscripción»: Servicio de pago que, conectado al Collar
          Inteligente, permite, según los servicios incluidos en la suscripción
          contratada: i) averiguar y seguir la ubicación de su mascota
          («Seguimiento en línea»); ii) diagnosticar el estado de forma de su
          mascota; y/o, iii) cualquier otro servicio que pudiere ofrecerse al
          momento de contratar la Suscripción. Antes de contratar la
          Suscripción, el cliente deberá escoger las características que desee
          incluir en su Suscripción. Las características del Plan de Suscripción
          se describen en la Página Web y al momento de realizar la contratación
          a través de la Aplicación Kolyy, como se describe abajo. El Plan de
          suscripción puede ser por periodo mensual, trimestral o anual, a
          elección del Usuario. Cada Collar Inteligente debe tener asociada una
          Suscripción.
          <br />
          <br />
          El Collar Inteligente y los Accesorios serán también denominados
          conjuntamente como los «Productos» e indistintamente cada uno de ellos
          como el «Producto».
          <br />
          <br />
          La Suscripción será también denominada como los «Servicios» o
          «Servicio», indistintamente.
        </p>
        <h6>III. Definiciones</h6>
        <p>
          En el futuro se aplicarán las siguientes definiciones, además de las
          definiciones que figuran entre paréntesis, para estas Condiciones
          Generales de Contratación (en lo sucesivo, «CGC»):
          <br />
          <br />
          1. «Aplicación Kolyy»: aplicación de software descargable para
          dispositivos móviles (según se describe en la Página Web) titularidad
          de Kolyy que permite el acceso a los servicios de Suscripción
          contratados por el Cliente en el marco del plan de subscripción
          seleccionado. En la misma se venderán de forma online Productos y
          Servicios de Kolyy o, en su caso, de terceros. A la Aplicación Kolyy
          le será de aplicación el Acuerdo de Licencia de Usuario Final (“EULA”)
          disponible en la Aplicación Kolyy y todas las operaciones realizadas
          en la misma, ya sean de forma gratuita o onerosa, se regirán por las
          presentes Condiciones Generales de Contratación de Productos y
          Servicios. <br /> <br />
          2. «Sitio web»:{" "}
          <a
            style={{ color: "#30AAAA" }}
            rel="noreferrer"
            target="_blank"
            href="https://Kolyy.com"
          >
            https://Kolyy.com
          </a>
          , titularidad de Kolyy. Al Sitio Web le serán de aplicación las
          Condiciones de Uso disponibles en el Aviso Legal{" "}
          <a
            style={{ color: "#30AAAA" }}
            rel="noreferrer"
            target="_blank"
            href="https://kolyy.com/aviso-legal"
          >
            www.kolyy.com/aviso-legal
          </a>
          <br />
          <br />
          3. «Tienda en línea Web»: La tienda en línea Web titularidad de Kolyy
          será accesible desde el Sitio Web. En la misma se venderán de forma
          online los Productos y, en su caso, Servicios de Kolyy o, en su caso,
          de terceros. A la Tienda Online le serán de aplicación las Condiciones
          de Uso disponibles en el Aviso legal{" "}
          <a
            style={{ color: "#30AAAA" }}
            rel="noreferrer"
            target="_blank"
            href="https://kolyy.com/aviso-legal"
          >
            www.kolyy.com/aviso-legal
          </a>{" "}
          y todas las operaciones realizadas en la misma, ya sean de forma
          gratuita o onerosa, se regirán por las presentes Condiciones Generales
          de Contratación de Productos y Servicios.
          <br />
          <br />
          4. «Usuario» / «Cliente»: Parte contratante del Servicio y/o Productos
          ofrecidos por Kolyy. En el ámbito del Servicio se hará referencia
          generalmente a «Usuario» y, en el ámbito de la Tienda en línea y venta
          de Productos se hará referencia generalmente a «Cliente». «Usuario» y
          «Cliente» también se denominarán juntos como «Parte contratante».
        </p>

        <h6>
          IV. ¿Cuándo se aplican las Condiciones Generales de Contratación?
        </h6>
        <p>
          1. Las siguientes condiciones establecen las condiciones por las que
          se rige la utilización y/o compra de cualquiera de los Productos o
          Servicio, y/o de cualquier otro producto o servicio disponible en la
          Tienda en línea accesible a través del Sitio Web y/o de la Aplicación
          Kolyy.
          <br />
          <br />
          2. Al acceder a la Aplicación Kolyy y/o al realizar un pedido a través
          de la Tienda en línea, el Cliente consiente quedar vinculado por estas
          Condiciones Generales de Contratación (“CGC”)
          (www.kolyy.com/terminos-y-condiciones), así como Aviso Legal del Sitio
          Web ({" "}
          <a
            style={{ color: "#30AAAA" }}
            rel="noreferrer"
            target="_blank"
            href="https://kolyy.com/aviso-legal"
          >
            www.kolyy.com/aviso-legal
          </a>
          ), la Política de Protección de Datos de Kolyy ({" "}
          <a
            style={{ color: "#30AAAA" }}
            rel="noreferrer"
            target="_blank"
            href="https://kolyy.com/politica-de-privacidad"
          >
            www.kolyy.com/politica-de-privacidad
          </a>
          ) y Cookies de Kolyy ({" "}
          <a
            style={{ color: "#30AAAA" }}
            rel="noreferrer"
            target="_blank"
            href="https://kolyy.com/politica-cookies"
          >
            www.kolyy.com/politica-cookies
          </a>
          ) , por lo que si el Cliente no está de acuerdo con cualquiera de
          ellas, no debe usar el Sitio web y/o Aplicación Kolyy ni realizar
          ningún pedido a través de las mismas. Estas CGC afectan a los derechos
          y obligaciones del Cliente por lo que debe leerlas detenidamente.
          <br />
          <br />
          3. Al usar el presente Sitio Web, la Aplicación Kolyy y/o, realizar un
          pedido a través del Sitio Web, el Cliente o Usuario representa y
          garantiza que es mayor de 18 años.
        </p>

        <h6>V. Procedimiento de compra de los Productos</h6>

        <p>
          1. Para acceder a la Tienda en línea deberá acceder a través del Sitio
          web o de la misma Aplicación Kolyy. A través de la Tienda en línea Web
          Toda la información del Sitio Web, la Tienda en línea, el Producto,
          las CGC, el Sitio web y la Aplicación Kolyy está disponible en
          español. <br />
          <br /> 2. El pedido se realiza en los siguientes pasos: <br />
          <br />
          a) Selección del Producto deseado añadiendo a la cesta de compra
          haciendo clic sobre el icono correspondiente; <br /> <br />
          b) Para realizar el pedido se requerirá la introducción de la
          dirección de envío/datos de contacto (nombre, apellido(s), calle y
          número, código postal, ciudad, país, dirección e-mail, número de
          teléfono para que el transportista pueda ponerse en contacto si fuera
          necesario), así como una dirección de facturación diferente en su
          caso; y elección de la forma de envío. Los datos personales
          proporcionados por el Cliente serán tratados de acuerdo con lo
          establecido en nuestra Política de Protección de datos (
          <a
            style={{ color: "#30AAAA" }}
            rel="noreferrer"
            target="_blank"
            href="https://kolyy.com/politica-de-privacidad"
          >
            www.kolyy.com/politica-de-privacidad
          </a>
          ); <br />
          <br /> Antes de finalizar el pedido se presentará al Cliente un
          resumen del mismo identificando el Producto añadido en la cesta de
          compra, su precio total (transporte e impuestos incluidos) con cada
          uno de los conceptos desglosados y los datos de envío del pedido. Tras
          la verificación de los datos del pedido en la cesta de la compra, el
          Cliente deberá confirmar el pedido haciendo clic en el botón
          “confirmar pago” («Confirmación de Pedido»).
          <br />
          <br />
          c) En caso de que antes de la Confirmación de Pedido el Cliente
          detectase un error en su pedido, podrá modificarlo haciendo clic en el
          apartado “Editar” o “Eliminar”. En caso de detectar un error en su
          pedido una vez finalizado el proceso de Confirmación de Pedido, el
          Cliente deberá ponerse en contacto con Kolyy a través de
          support@kolyy.com;
          <br />
          <br />
          d) Tras la Confirmación de Pedido, el Cliente será reconducido a una
          plataforma de pago para efectuar el pago según lo previsto en la
          sección VII (Precios, gastos de envío y formas de pago). <br />
          <br />
          e) Al confirmar el pedido, el Cliente reconoce y acepta la información
          precontractual disponible en la Página web o en la Aplicación Kolyy y
          estas CGC como determinantes para la relación contractual con Kolyy.
          <br />
          <br />
          f) Kolyy enviará al Cliente acuse de recepción de la Confirmación del
          Pedido. El acuse de la recepción de la Confirmación del Pedido no
          supondrá la aceptación del pedido por parte de Kolyy. Todos los
          pedidos están sujetos a la aceptación expresa en un plazo razonable
          por parte de Kolyy, que no se denegará a menos que exista causa
          justificada como no encontrarse disponible el bien o servicio
          contratado, errores sustanciales en el precio o descripción del
          Producto y/o Servicio; o cualquier otro motivo que dificultare o
          hiciere imposible la entrega en un plazo razonable. Se entenderá
          aceptado el pedido por parte de Kolyy cuando notifique al Cliente
          mediante un correo electrónico confirmando (a) que el pedido está
          siendo procesado; o bien (b) que el Producto ha sido enviado o que el
          Servicio ha sido puesto a disposición del Cliente (la «Aceptación del
          Pedido»). En la Aceptación del Pedido, Kolyy incluirá toda la
          información relativa al contrato celebrado y un link a las CGC
          aplicables. Se recomienda al cliente acceda a las CGC y las descargue
          a su dispositivo para almacenarlas. En cualquier caso Kolyy almacenará
          una copia del contrato durante un plazo razonable y estará disponible
          bajo solicitud del Usuario.
          <br />
          <br />
          g) El contrato se entiende perfeccionado por medio de la Aceptación
          del Pedido.
        </p>

        <h6>V. Procedimiento de compra de los Productos</h6>

        <p>
          1. Para la utilización de la Suscripción es necesaria (a) la posesión
          de un Collar Inteligente Kolyy plenamente operativo; (b) la descarga
          de la Aplicación Kolyy desde la plataforma de gestión disponible; (c)
          el registro del Usuario y la contratación del Servicio de Suscripción
          a través de la Aplicación Kolyy, seleccionando el correspondiente plan
          de suscripción (en lo sucesivo, el «Plan de subscripción»). Al
          finalizar efectivamente el registro y la contratación de la
          Suscripción, Kolyy concederá acceso al Servicio dentro de un plazo
          razonable y nunca más de tres (3) días desde la finalización completa
          del registro y la contratación del Servicio. <br />
          <br /> <b>2. Operatividad del Collar Inteligente:</b> <br />
          <br /> a) Para que las funcionalidades del Collar Inteligente y de la
          Aplicación Kolyy sean operativas, el Cliente deberá contratar la
          Suscripción. Cada Collar Inteligente deberá llevar asociada una
          Suscripción independiente. <br />
          <br /> b) Para que la Suscripción funcione adecuadamente, el Usuario
          será responsable de comprobar la operatividad del Collar Inteligente
          de forma periódica y asegurar que su mascota lleva el Collar
          Inteligente. <br />
          <br /> c) En concreto, pero sin limitaciones, el Usuario debe
          comprobar que el Collar Inteligente (a) esté en buenas condiciones;
          (b) disponga de batería; (c) tenga las actualizaciones necesarias
          proporcionadas por Kolyy. <br />
          <br /> d) En caso de que el Cliente no instalara las actualizaciones
          al Collar Inteligente o no comprobará las condiciones del Collar
          Inteligente, Kolyy no se hará responsable por el malfuncionamiento de
          los servicios incluidos en la Suscripción. <br />
          <br /> <b>3. Registro Usuario</b> <br />
          <br /> a) El Cliente deberá registrarse en la Aplicación Kolyy para
          contratar y hacer uso del Servicio contratado (en adelante, la
          «Cuenta»). <br />
          <br /> b) Para el registro, se solicitarán datos del Usuario (tales
          como: nombre, apellido, correo electrónico, teléfono, dirección de
          envío y datos bancarios) así como del Collar Inteligente (tales como:
          identificador de serie del Collar Inteligente, país de uso del collar
          inteligente, etc.) y de su mascota (tales como: nombre, raza, edad,
          sexo, esterilización, peso, condición corporal, etc.) con la finalidad
          del buen uso del Servicio (en adelante, los «Datos de Registro»). Los
          datos personales del Usuario se tratarán de conformidad con la
          Política de Protección de Datos de Kolyy
          (https://kolyy.com/es/política-cookies/). <br />
          <br /> c) El Cliente declara y acepta: (i) suministrar información
          veraz, precisa, actualizada y completa en relación con los Datos de
          Registro; (ii) no divulgar su contraseña ni su código identificativo;
          (iii) mantener los Datos de Registro y cualquier otra información
          suministrada constantemente actualizada, completa y precisa. Kolyy se
          reserva el derecho de verificar la exactitud en ciertos casos. No se
          permiten pseudónimos. <br />
          <br />
          d) Kolyy se reserva el derecho de bloquear de manera temporal o
          definitiva, a su discreción, el acceso del Usuario a su Cuenta en caso
          de sospecha fundada de abuso o uso indebido (en especial, para
          registrar patrones de movimiento de terceras personas) o cuando se
          detectase una vulneración de alguna disposición de las presentes CGC,
          incluida la falta de pago de las tarifas a su vencimiento. <br />
          <br /> <b>4. Contratación del Plan de Suscripción</b>
          <br />
          <br />
          a) Una vez el Usuario se haya registrado en la Aplicación Kolyy, la
          Aplicación Kolyy le solicitará el código del Collar Inteligente (en
          adelante, el «Código») para poder vincular su Cuenta al Collar
          Inteligente y contratar la Suscripción. El Usuario, tras introducir el
          Código, seleccionar el Plan de subscripción correspondiente — debiendo
          seleccionar el intervalo de pago y la forma de pago— haciendo clic en
          las casillas de selección correspondiente o seleccionando en un menú
          desplegable. Haciendo clic en el campo «Pedir de manera vinculante» el
          Usuario contrata el Plan de Suscripción elegido, y será redirigido a
          la plataforma de pago correspondiente para efectuar el pago, momento
          en el cual se confirmará el contrato. La información relevante del
          Plan de Suscripción se pondrá de nuevo en conocimiento del Usuario de
          forma recopilada tras la Aceptación del Pedido por Kolyy. <br />
          <br /> b) Las características del Plan de Suscripción se describen en
          el Sitio Web y en la Aplicación Kolyy. El Plan de suscripción puede
          ser por periodo mensual, trimestral o anual, a elección del Usuario y
          su renovación será automática hasta la cancelación de la forma
          descrita en las presentes CGC. Pueden ofrecerse Planes de suscripción
          con distintas características, aconsejamos al Cliente leer
          detenidamente las características de los distintes planes de
          suscripción antes de la contratación. <br />
          <br /> c) La selección de un Plan de subscripción se realiza en el
          momento de contratar el Servicio mediante la Aplicación Kolyy según el
          procedimiento establecido en este apartado. Kolyy podrá modificar y
          añadir nuevos planes de suscripción en cualquier momento. En caso de
          que la modificación afecte el Plan de Suscripción contratado, se
          aplicará lo dispuesto en el apartado XII de las CGC «Modificación de
          las Condiciones Generales». <br /> <br />
          Los precios especificados en el Sitio Web y la Aplicación Kolyy para
          la contratación del Servicio- según el intervalo de pago seleccionado
          por el Usuario- para el Plan de subscripción correspondiente, se
          entienden, siempre que no se indique expresamente lo contrario,
          incluido cualquier impuesto sobre el valor añadido aplicable. <br />
          <br />
          e) Pago y ciclo de facturación: <br />
          <br />
          El primer pago del Plan de subscripción elegido («Primer Pago») se
          realizará al momento de activar la suscripción («Activación
          Suscripción») mediante el método de pago elegido por el Usuario . Se
          entenderá por Activación Suscripción el momento en que el Cliente
          contrate la Suscripción a través de la Aplicación Kolyy de la forma
          descrita en el apartado VI.4.1. <br />
          <br />
          El ciclo de facturación del Servicio comenzará en el día de la
          Activación Suscripción. <br />
          <br />
          - En el Plan de suscripción mensual el ciclo de facturación será
          mensual. <br />
          - En el Plan de suscripción trimestral el ciclo de facturación será
          trimestral. <br />- En el Plan de suscripción anual el ciclo de
          facturación será anual. <br />
          <br />
          Los pagos sucesivos periódicos de la Suscripción («Pagos Periódicos»),
          se realizarán a partir del ciclo de facturación siguiente a la
          Activación Suscripción, cargándose su método de pago conforme el ciclo
          de facturación y en la cantidad de su Plan de suscripción. Por
          ejemplo, en el caso de un Plan de Suscripción Mensual en el que la
          Activación Suscripción se realizara el 5 de abril, se hará el Primer
          Pago el 5 de abril y los Pagos Periódicos se realizaran el 5 mayo, 5
          de junio y sucesivos. <br />
          <br />
          f) La subscripción al Servicio se renueva automáticamente al final del
          ciclo de facturación del Servicio según el Plan de subscripción
          (mensual, trimestral o anual) que haya seleccionado el Usuario, a
          menos de que el Usuario fallara el pago por la renovación, en cuyo
          caso la subscripción finalizará automáticamente al final del ciclo de
          facturación abonado por el Usuario o cancelara el servicio de
          conformidad con lo previsto en el apartado VI.7
          <br />
          <br />
          g) En caso de impago total o parcial del pago inicial o cualquiera de
          los importes periódicos debidos, se intentará cargar la cuenta del
          Cliente hasta un máximo de 3 veces en un periodo de siete (7) días. En
          caso de que no se reciba el pago en dicho periodo adicional, Kolyy
          podrá rescindir o suspender inmediatamente al Usuario el acceso y uso
          del Servicio, en su totalidad o en parte. <br />
          <br /> h) Kolyy remitirá al Cliente las facturas correspondientes al
          importe debido según el Plan de suscripción elegido por el Cliente a
          través del correo electrónico proporcionado a la contratación del
          Servicio.
          <br />
          <br />
          <b>5. Compromisos, obligaciones y normas de conducta del Usuario</b>
          <br />
          <br />
          a) Para poder utilizar el Servicio de Kolyy el Usuario deberá usar
          tecnologías (de navegador) actuales o permitir su uso (p. ej.,
          activación de Java Script, cookies, ventanas emergentes) o utilizar
          siempre una aplicación Kolyy actualizada. La lista de
          interoperabilidad está disponible en la descripción del Producto y el
          Servicio en la Página Web. Si se utilizan tecnologías no actuales,
          aplicaciones no actuales o terminales obsoletos, es posible que el
          Usuario no pueda utilizar el Servicio en su totalidad. Kolyy pondrá a
          disposición de los Usuarios la Aplicación Kolyy compatible con las dos
          últimas actualizaciones de la versión principal para Android e iOS.
          Cada vez que salga una nueva actualización de la versión principal
          para Android e iOS, Kolyy dispondrá de un plazo razonable para hacer
          compatible la Aplicación Kolyy con dicha actualización.
          <br />
          <br />
          b) El Usuario deberá tratar los datos de acceso (la combinación de
          dirección e-mail y contraseña) de manera estrictamente confidencial y
          protegerlos del acceso no autorizado por parte de terceros. Este no
          podrá revelar estos datos de acceso a nadie, incluidos los empleados
          de Kolyy. Cuando el Usuario tenga motivos para creer que terceros han
          tomado conocimiento de sus datos de acceso de alguna forma, este está
          obligado a modificar su contraseña inmediatamente. <br />
          <br />
          c) El Usuario se compromete a usar el Servicio ofrecido por Kolyy de
          la manera prevista y, durante su uso, a abstenerse de realizar
          acciones que puedan dañar o poner en peligro a Kolyy, a otros usuarios
          de Kolyy o a terceros y/o que puedan restringir la disponibilidad del
          Servicio para otros usuarios. El uso previsto también incluye el
          cumplimiento de todos los avisos, recomendaciones y similares que
          Kolyy realice en el momento de celebración del contrato o
          posteriormente, en su página de inicio, en las instrucciones de uso
          y/o en otros documentos puestos a disposición del Usuario. Los Collar
          Inteligentees han sido especialmente diseñados para la localización de
          mascotas y, por lo tanto, solo deben utilizarse para este fin. En
          algunos países y regiones existen leyes sobre la determinación digital
          de la posición y la localización de animales, personas y/u objetos,
          solo el Cliente o el Usuario del Collar Inteligente y de los Servicios
          será responsable de cumplir con dichas leyes o normas. Kolyy no tendrá
          ninguna responsabilidad por el incumplimiento de dichas leyes por el
          uso indebido de sus Productos o Servicios por parte del Cliente o
          Usuario. <br />
          <br /> d) Kolyy ofrece al Usuario la posibilidad de añadir contenidos
          a las bases de datos, comunicarse e interactuar con otros usuarios. El
          Usuario garantiza que no abusará del Servicio de Kolyy. En especial,
          el Usuario deberá cumplir con las siguientes normas: <br />
          <br />
          i) La introducción de cualquier dato personal en el perfil público del
          Usuario se realiza bajo su propio riesgo. <br />
          <br />
          ii) También queda prohibida la publicación de datos de terceros (p.
          ej., la creación de un perfil para un tercero) sin su consentimiento o
          subir una imagen que muestre a un tercero sin su consentimiento.{" "}
          <br />
          <br /> iii) El Usuario será el único responsable de los contenidos
          guardados en su perfil. El Usuario se compromete a cumplir con todas
          las disposiciones legales aplicables, al publicar contenidos en su
          perfil, en especial, a no difundir contenidos, materiales, información
          y/o fotografías éticamente inaceptables, pornográficos, obscenos,
          racistas, que exalten la violencia, que resten importancia a la
          violencia, radicales de derecha/izquierda, ofensivos o ilegales de
          otra forma. <br />
          <br /> iv) El Usuario también se compromete a no amenazar ni insultar
          a terceros y a otros usuarios y/o a no violar sus derechos
          (personales). <br />
          <br />
          v) Queda prohibido el uso de los Productos de Kolyy con fines
          comerciales (p. ej., publicitar, promocionar y ofertar mercancía y
          servicios). <br />
          <br /> e) El Usuario se compromete a (a) usar el Sitio Web y las
          Aplicaciones Kolyy conforme las Condiciones de USO y, en caso de que
          resultare de aplicación por haberse el cliente descargado la
          AplicaciónKolyy, el EULA; (b) no intervenir en los procesos de los
          servicios mediante la manipulación del software, del software propio o
          mediante el acceso automatizado al software de Kolyy; (b) no acceder a
          las funciones de localización del Collar Inteligente o a los datos de
          Kolyy por medios distintos de los que se ponen a disposición del
          Usuario en el marco del Plan de subscripción correspondiente. <br />
          <br />
          f) Independientemente de cualquier consecuencia civil o penal, el
          Usuario deberá indemnizar y eximir a Kolyy de cualquier daño —de
          cualquier tipo— y de toda reclamación por parte de terceros —de
          cualquier tipo— que resulte de la violación de las obligaciones del
          Usuario según este apartado.
          <br />
          <br />
          g) En caso de incumplimiento por parte del Usuario de estas
          condiciones de contrato, Kolyy se reserva el derecho, a su libre
          discreción y sin necesidad de previo aviso, de (i) amonestar al
          Usuario; (ii) eliminar contenidos del Usuario; (iii) bloquear al
          Usuario de manera temporal o permanente; (iv) rescindir la relación
          contractual; (v) eliminar la Cuenta de Usuario; (vi) retener las
          cantidades abonadas por el servicio por el Cliente; o/y (vii) exigir
          indemnización de daños y perjuicios. Las acciones serán acumulables.
          El ejercicio de una o más de estas acciones, no será obstáculo para
          que Kolyy pueda ejercer otra de estas acciones o pueda ejercer otros
          de sus derechos establecidas en estas CGC. <br />
          <br />
          <b> 6. Servicio y disponibilidad</b> <br />
          <br />
          a) Seguimiento en línea. El Usuario tiene la posibilidad de consultar
          los datos de localización por medio de la Aplicación Kolyy para
          averiguar y seguir la ubicación de su mascota, siempre que tenga
          contratada la Suscripción que incluya seguimiento en línea. <br />
          <br /> Los datos estarán disponibles en intervalos de 1 minuto, pero
          es posible que en ocasiones se produzca un ligero retraso en la
          obtención de datos por causas ajenas a la voluntad de Kolyy, incluido
          pero no limitado a la falta de cobertura o factores meteorológicos.{" "}
          <br />
          <br />
          b) Diagnostica el estado de forma de su mascota. El Collar Inteligente
          registra la actividad de su mascota (número de pasos, tiempo activo,
          tiempo en reposo y paseos, así como otras informaciones que puedan
          llegar a registrarse a través del Collar Inteligente del estado de
          forma de su mascota). La información no tendrá consideración en ningún
          caso de asesoramiento veterinario o sobre el estado de salud de su
          mascota. <br />
          <br />
          La información podrá ser usada de forma anonimizada para estudios
          sobre el estado de forma de las mascotas. <br />
          <br /> c) Kolyy no garantiza una disponibilidad continua de su
          Servicio. No pueden descartarse tiempos de inactividad debidos al
          mantenimiento, actualizaciones de software y circunstancias (tales
          como problemas técnicos en el ámbito de la transmisión de datos
          móviles, falta de cobertura de red, problemas de conexión, problemas
          con la disponibilidad de uno o más proveedores de telefonía móvil,
          fallos en la red de terceros, factores meteorológicos) que no se
          encuentren en el ámbito de influencia directa de Kolyy y que, por lo
          tanto, están fuera del control de Kolyy. Kolyy no será responsable en
          estos casos por la interrupción o el malfuncionamiento del Servicio.
          En estes casos, el Usuario no podrá hacer valer ningún derecho a una
          indemnización por daños y perjuicios y/o de garantía por fallos sin
          culpabilidad <br />
          <br /> d) En el caso de interrupciones provocadas por Kolyy en el
          acceso al Servicio superiores a 72h, el Usuario solo tendrá derecho a
          una reducción proporcional del importe del Plan de Suscripción. En
          caso de interrupciones inferiores a 72h, el Usuario no tendrá derecho
          a indemnización. <br />
          <br /> e) A pesar de que el Plan de Suscripción está disponible de
          forma ilimitada, Kolyy se reserva el derecho a finalizar la sesión de
          seguimiento en directo si no se continúa con la sesión de seguimiento
          en directo en curso o si la solicitud correspondiente no se renueva
          dentro de un cierto período de tiempo para evitar un abuso en el uso
          del Servicio y para ahorrar en el consumo de energía del Collar
          Inteligente. El Usuario podrá en cualquier momento volver a acceder a
          su sesión. <br />
          <br /> <b>
            7. Cancelación y terminación anticipada del Servicio
          </b>{" "}
          <br />
          <br /> a) El Usuario puede cancelar online el servicio en cualquier
          momento a través del apartado de la Aplicación Kolyy “Gestionar mis
          suscripciones”, con un preaviso mínimo de 15 días antes del último día
          del ciclo de facturación actual. En caso de realizar el preaviso
          dentro de plazo, la cancelación del Servicio se hará efectiva el
          último día del ciclo de facturación actual, en caso contrario, la
          cancelación del Servicio se hará efectiva el último día del siguiente
          ciclo de facturación; en cualquier caso, el Cliente podrá hacer uso
          del Servicio hasta su cancelación. No se efectuará ningún tipo de
          reembolso en el caso de que no se usare el Servicio. Por ejemplo, si
          ciclo de facturación trimestral actual abarca del 15 de enero al 15 de
          abril y el Cliente envía una solicitud de cancelación a Kolyy el 25 de
          febrero, el Servicio se cancelará el 15 de abril. Sin embargo, si
          enviase la solicitud de cancelación a 10 de abril, el Servicio se
          cancelará el 15 de julio. <br />
          <br /> b) Kolyy tiene derecho a interrumpir, suspender o resolver el
          acceso al Sitio web, Aplicación Kolyy y/o al uso del Servicio sin
          perjuicio de los demás derechos y recursos del Kolyy detallados en
          estas CGC, incluido su derecho a reclamar daños y perjuicios, siempre
          que se dé alguna de las siguientes condiciones: (i) que el Usuario
          incumpla sus obligaciones en virtud de las presentes CGC; (ii) en caso
          de declaración de concurso de Kolyy; (iii) en caso de que el negocio
          de Kolyy sea cerrado o liquidado. <br />
          <br /> c) No se entenderá en ningún caso cancelado el Servicio
          simplemente por la falta de uso del Servicio o conexión a las
          Aplicaciones Kolyy. <br />
          <br /> d) En caso de que se cancele su Cuenta por los motivos
          indicados en el apartado VI.7.a. o VI.7.b.(i), el Usuario no tendrá
          derecho a recibir ningún reembolso como resultado de la cancelación.{" "}
          <br />
          <br /> En caso de que se cancele su Cuenta por los motivos indicados
          en el punto VI.7.b.(ii) y/o VI.7.b.(iii), el Usuario solamente tendrá
          derecho al reembolso proporcional del importe abonado y no disfrutado
          (eg. En el caso de un Plan de Suscripción anual pagado a inicio de año
          que se cancelara cuando el Cliente todavía tiene derecho a disfrutar 4
          meses bajo el Plan de Suscripción contratado, tendrá derecho a la
          devolución de la parte proporcional a 4 meses). <br />
          <br /> e) Tras la terminación del Servicio Kolyy hará que su Cuenta
          sea inaccesible para el Usuario, no estando Kolyy obligado en ningún
          caso a proporcionarle ningún contenido u otra información ni a
          convertir o exportar dicha información, salvo disposición legal en
          contrario <br />
          <br /> f) El Cliente podrá dar de alta una nueva Suscripción en
          cualquier momento en las condiciones que sean aplicables al momento de
          contratar la nueva suscripción. En ningún caso tendrá derecho a
          solicitar la aplicación de ninguna bonificación, promoción o condición
          particular de la suscripción que hubiese sido cancelada.
        </p>
        <h6>VII. Precios, gastos de envío y formas de pago</h6>
        <p>
          1. Los precios se indican en la Tienda en línea y/o en la Aplicación
          Kolyy en cada uno de los Productos y/o Servicios. Todos los precios
          indicados por Kolyy para los Productos y/o Servicio se entienden, a no
          ser que se indique expresamente lo contrario, en euros, con el
          impuesto sobre el valor añadido aplicable, pero sin los aranceles de
          importación a terceros países (países fuera de la UE/EEA). Cualquier
          tasa, impuesto y/o arancel que pudiera generarse por la exportación
          del Producto y/o Servicio fuera de la UE/EEA correrán a cargo del
          Cliente. <br />
          <br /> 2. A menos de que se indique lo contrario en el momento de
          realizar la compra, los precios indicados en la Tienda en línea no
          incluyen los gastos de envío. Las opciones de envío se darán a conocer
          por separado en el marco del proceso del pedido en caso de
          contratación de un Producto. Los gastos de envío siempre correrán a
          cuenta del Cliente. <br />
          <br />
          En la fase actual, Kolyy solamente realiza entrega del Collar
          Inteligente en territorio español y ofrece la Suscripción en
          territorio español. <br />
          <br />
          El Collar Inteligente puede activarse en todos los países miembros de
          la Unión Europea (listados abajo), siempre sujeto a la cobertura y el
          operador de cada territorio. En el caso de que un Cliente deseara
          activar la Suscripción fuera de territorio español, deberá contactar
          con Kolyy para activar las funcionalidades; en caso de que la
          activación de las funcionalidades conllevara gastos a Kolyy, el
          Cliente será informado de los mismos y Kolyy tendrá derecho a
          repercutir al Cliente dichos gastos. <br />
          <br />
          Listado de países UE: Alemania, Austria, Bélgica, Bulgaria, Chipre,
          Croacia, Dinamarca, Eslovaquia, Eslovenia, España, Estonia, Finlandia,
          Francia, Grecia, Hungría, Irlanda, Italia, Letonia, Lituania,
          Luxemburgo, Malta, Países Bajos, Polonia, Portugal, República Checa,
          Rumania y Suecia. <br />
          <br /> 3. Kolyy únicamente aceptará pagos realizados a través de
          tarjeta de crédito o de débito: PayPal, Visa, Visa Electron,
          MasterCard y American Express. El cargo se realizará online, es decir,
          en tiempo real, a través de la plataforma de pago correspondiente, una
          vez se haya comprobado que los datos comunicados son correctos, sin
          perjuicio de los dispuesto en el apartado VI.4. para el caso del pago
          recurrente del Servicio. <br />
          <br /> Kolyy utiliza un procesador de pagos de terceros para facturar
          y procesar el pago del Producto y Servicio. El procesamiento de los
          pagos y los datos personales que el Cliente introduzca en la
          plataforma de pagos, están sujetos a los términos, condiciones y
          políticas adicionales del procesador de pagos. Kolyy se reserva el
          derecho de cambiar o añadir procesadores de pago de terceros a su
          discreción. <br />
          <br /> El Cliente podrá cambiar el método de pago introducido para la
          suscripción a través del apartado de la Aplicación Kolyy “Gestionar
          mis suscripciones”, que le redirigirá a la plataforma de pagos para
          actualizar el pago. <br />
          <br />
          4. En todo caso, Kolyy se reserva el derecho de modificar y/o
          actualizar los precios previstos en la Tienda en línea para contratar
          los Productos y/o Servicio en cualquier momento sin efectos
          retroactivos a su entera discreción. En caso de cambio y/o
          actualización de precio en el Servicio, Kolyy lo notificará al Cliente
          en las condiciones establecidas en el punto XII de Modificación de las
          Condiciones Generales. Cualquier cambio y/o actualización en los
          precios del Servicio entrará en vigor para el próximo ciclo de
          facturación, una vez hayan transcurrido treinta (30) días de la
          notificación por parte de Kolyy de dichos cambios de acuerdo con el
          punto XII de Modificación de las Condiciones Generales. <br />
          <br />
          5. Kolyy se reserva el derecho a retener los Productos hasta que se
          haya verificado el pago. En caso de demora en el pago por parte de la
          parte contratante, Kolyy tiene derecho, a su propia discreción, a
          extinguir el contrato y/o exigir la indemnización por los daños reales
          sufridos o los intereses de demora al tipo legal. En caso de demora en
          el pago, la parte contratante se compromete a reembolsar a Kolyy los
          gastos monitorios y de cobro en los que haya incurrido, siempre que
          sean necesarios en el marco del procedimiento. La reivindicación del
          derecho de otros daños adicionales no se ve afectada.
        </p>
        <h6>VIII. Condiciones de entrega de los Productos y Servicios</h6>
        <p>
          1. Kolyy solo estará obligada a entregar el Producto y/o dar acceso al
          Cliente al Servicio cuando el Cliente haya cumplido con todas sus
          obligaciones, en especial, con el pago del precio de compra, incluidos
          todos los posibles gastos de envío.
          <br />
          <br />
          2. En la Aceptación del Pedido, se indicará la fecha prevista de
          entrega del Producto y/o acceso al Servicio, que en ningún caso será
          superior a treinta (30) días desde la Aceptación del Pedido.
          <br />
          <br />
          El plazo excepcionalmente podrá ser superior en el caso de preventas
          (ver apartado XVIII «Venta de Productos en Preventa») debido a las
          circunstancias de la preventa.
          <br />
          <br />
          3. Si por algún motivo Kolyy no pudiese entregar el Producto y/o dar
          acceso al Servicio en un plazo de treinta (30) días desde la
          Aceptación del Pedido o en el plazo inicialmente indicado para la
          entrega, Kolyy informará al Cliente de tal circunstancia y le dará la
          opción de seguir adelante con la compra estableciendo una nueva fecha
          de entrega o bien anular el pedido con el reembolso total del precio
          pagado.
          <br />
          <br />
          4. El riesgo del Producto será a cargo del Cliente a partir del
          momento de su entrega.
          <br />
          <br />
          5. Se entenderá que se ha producido la entrega del Producto en el
          momento en que el Cliente o un tercero autorizado por el Cliente
          adquiera la posesión material del Producto contratado, lo que se
          acreditará mediante la firma de la recepción del Producto en la
          dirección de entrega acordada. En caso de que transcurridos 15 días
          desde que su pedido esté disponible para su entrega y el pedido no se
          haya podido entregar por causa no imputable a Kolyy, se entenderá que
          el Cliente desiste del contrato y se dará por resuelto el contrato.
          <br />
          <br />
          6. Se entenderá que se ha producido la entrega del Servicio cuando el
          servicio digital sea accesible para el Usuario.
        </p>
        <h6>IX. Garantías y Responsabilidades</h6>
        <p>
          <b>1. Producto</b>
          <br />
          <br />
          a) Garantía legal. En caso de falta de conformidad de los Productos
          vendidos por Kolyy, el Cliente tendrá derecho única y exclusivamente a
          reclamar la garantía legal aplicable en el momento de entrega de los
          bienes en España o, en el caso de que no resultare de aplicación, la
          de país donde se haya entregado el Producto al Cliente. A menos que la
          ley de aplicación disponga otra cosa, en caso de falta de conformidad
          del Producto, el Cliente tendrá derecho a elegir entre reparación o
          sustitución, salvo que una de estas dos opciones resultare imposible o
          que en comparación con la otra medida correctora, suponga costes
          desproporcionados para Kolyy. Salvo disposición legal en contrario, el
          Cliente únicamente podrá pedir la reducción del precio y/o resolución
          del contrato en las condiciones legalmente establecidas en el caso que
          la reparación o sustitución no sean posibles.
          <br />
          <br />
          En la medida permitida por ley, Kolyy no ofrece ninguna otra garantía
          sobre el Producto.
          <br />
          <br />
          b) Excepto en la medida que lo prohíba la ley, el Producto se
          proporciona «como está» y «como esté disponible» con todos sus
          defectos. Excepto lo previsto en las presentes CGC o en la medida que
          lo prohíba la ley, Kolyy niega expresamente todas las garantías de
          cualquier tipo, ya sean explícitas o implícitas.
          <br />
          <br />
          c) El Cliente reconoce y acepta que debe hacer un buen uso del Collar
          Inteligente y que la Garantía legal no cubrirá desperfectos que puedan
          causarse por mal uso del Collar Inteligente por el Cliente o su
          mascota. Se entenderá mal uso, entre otras, la presencia de golpes,
          rascadas, mordidas, humedad en el Collar Inteligente.
          <br />
          <br />
          d) El Collar Inteligente incorpora una batería. La batería que
          incorpora es una batería estándar de 500 ciclos. Un ciclo es un
          periodo en el cual una batería se agota por debajo de cierto nivel y
          luego se recarga. Pasados los 500 ciclos de carga, la batería puede
          disminuir su rendimiento.
          <br />
          <br />
          <b>2. Servicios, Sitio Web, Tienda en línea y Aplicaciones Kolyy</b>
          <br />
          <br />
          a) Kolyy prestará el Servicio conforme a las prácticas y estándares
          comerciales generalmente reconocidos e informará adecuadamente y en un
          plazo razonable de cualquier problema relativa al Servicio. Si el
          Servicio se presta en el Espacio Económico Europeo, Suiza o Reino
          Unido, Kolyy se compromete a cumplir con todas las garantías legales
          requeridas por la legislación aplicable. Si el Servicio no fuera
          conforme, el Usuario tendrá derecho a exigir que sean puestos en
          conformidad. Salvo disposición legal en contrario, el Cliente
          únicamente podrá pedir la reducción del precio y/o resolución del
          contrato en las condiciones legalmente establecidas en el caso que la
          puesta en conformidad del Servicio no sea posible.
          <br />
          <br />
          En la medida permitida por ley, Kolyy no ofrece ninguna otra garantía
          sobre el Servicio.
          <br />
          <br />
          b) Aunque Kolyy hace todo lo posible para proporcionar al Usuario una
          conexión de datos segura, Kolyy no asume ninguna responsabilidad por
          el hecho de que el transporte de datos a través de sistemas ajenos, en
          especial por Internet o redes de telecomunicaciones, pueda seguirse,
          registrarse o falsificarse por terceros.
          <br />
          <br />
          c) El uso del Sitio Web, Tienda en línea, Aplicación Kolyy y/o del
          Servicio por parte del Usuario se realiza exclusivamente bajo su
          propio riesgo y de forma voluntaria. Esto se aplica de manera
          ilimitada:
          <br />
          <br />
          i. al hardware que se usa, incluyendo, pero no limitado a, el
          smartphone o navegador correspondiente;
          <br />
          <br />
          ii. la descarga de contenidos propios y ajenos por parte del Usuario;
          así como
          <br />
          <br />
          iii. a cualquier empleo por parte del Usuario de los datos creados por
          Kolyy o puestos a disposición por Kolyy. El Usuario reconoce
          expresamente que dichos datos pueden ser incorrectos y que Kolyy,
          siempre que así lo permita la ley, no asume ninguna responsabilidad
          por la exactitud de dichos datos.
          <br />
          <br />
          d) Kolyy no ofrece ninguna garantía o declaración de que el Sitio web,
          Tienda en línea, Aplicación Kolyy y/o el Servicio cumpla o pueda
          cumplir los requisitos y expectativas del Usuario respecto de la
          finalidad, calidad, aptitud para cualquier propósito en particular,
          compatibilidad con los sistemas del Usuario, actualización más allá de
          un periodo razonable o que la herramienta, siempre estará disponible,
          completamente libre de errores o que toda la información proporcionada
          será completamente precisa.
          <br />
          <br />
          e) Además, Kolyy no asume ninguna responsabilidad por enlaces
          externos, anuncios u otras ofertas de información y publicidad que
          puedan colocarse para el Usuario. Los negocios jurídicos entablados
          entre el Usuario y un proveedor tercero (p. ej., a través de páginas o
          anuncios enlazados) llevan a relaciones contractuales exclusivamente
          entre el Usuario y el proveedor tercero. Kolyy no asume ninguna
          responsabilidad por los servicios de terceros.
          <br />
          <br />
          f) A excepción de los casos obligatorios por ley, Kolyy no incurrirá
          en responsabilidad por daños resultantes del uso de los contenidos
          accesibles a través del Sitio web, Tienda en línea, Aplicación Kolyy
          y/o Servicio en línea y móvil. Esto también se aplica a daños
          provocados por errores, problemas, virus o pérdidas de datos.
          <br />
          <br />
          g) El Usuario exime a Kolyy de todas las reclamaciones de terceros
          contra Kolyy debido a una violación de sus derechos por contenidos
          publicados por el Usuario dentro del Sitio web, Tienda en línea,
          Aplicación Kolyy y/o Servicio o por cualquier otro uso de este de las
          aplicaciones puestas a disposición a través de Kolyy. En este caso, el
          Usuario asumirá los costes de una defensa legal necesaria o solo
          oportuna de Kolyy, incluyendo todos los costes judiciales y abogadiles
          por el importe legalmente debido. Esto no se aplica si la infracción
          jurídica del derecho no es atribuible a un comportamiento culpable del
          Usuario.
          <br />
          <br />
          h) En caso de reivindicación por parte de terceros, el Usuario está
          obligado a informar de inmediato, de manera veraz e íntegra a Kolyy de
          toda la información que este tenga a su disposición y que pueda ser
          necesaria para el examen de las reclamaciones y para una defensa. No
          se verán afectados otros derechos a indemnización por daños y
          perjuicios de Kolyy de cara al Usuario.
          <br />
          <br />
          i) El Usuario declara y garantiza a Kolyy que:
          <br />
          <br />
          - tiene el poder y autoridad para aceptar las presentes CGC;
          <br />
          <br />
          - no utilizará el Sitio web, la Tienda en línea, la Aplicación Kolyy
          y/o el Servicio de manera que infrinja los derechos de Kolyy o
          terceros, incluidos, entre otros, los derechos de propiedad
          intelectual o los derechos en relación con la protección de la
          privacidad;
          <br />
          <br />
          - toda la información de la Cuenta que proporcione será completa,
          precisa y actualizada cuando se proporcione, y se actualizará según
          sea necesario para garantizar que permanezca completa, precisa y
          actualizada;
          <br />
          <br />
          - toda la información que proporcione a Kolyy o sus empleados y/o
          afiliados es precisa, completa y verdadera cuando se proporcione;
          <br />
          <br />
          j) Excepto en la medida que lo prohíba la ley, el Servicio se
          proporciona «como está» y «como esté disponible» con todos sus
          defectos. Excepto lo previsto en las presentes CGC o en la medida que
          lo prohíba la ley, Kolyy (i) niega expresamente todas las garantías de
          cualquier tipo, ya sean explícitas o implícitas, y en particular sin
          limitar la generalidad de lo anterior, Kolyy no ofrece ninguna
          garantía que el Servicio o la Aplicación Kolyy cumplirá con sus
          requisitos o que el Servicio o la Aplicación Kolyy estarán
          ininterrumpidos y libres de errores; (ii) no ofrece garantía de los
          resultados que puedan obtenerse a través del Servicio. No deberá
          considerarse como representación o garantía ningún tipo de consejo o
          información oral o por escrito que el Usuario obtenga a través del
          Servicio, la Aplicación Kolyy o por parte de Kolyy. Sin limitación de
          lo anterior, Kolyy no se hará responsable de ninguna pérdida de datos
          u otro contenido, fallo en el funcionamiento, error, accidente u otros
          efectos adversos que puedan ocurrir a partir del uso que haga del
          Servicio, el Sitio Web, la Tienda en línea o la Aplicación Kolyy.
          <br />
          <br /> <b>3. Pagos bancarios.</b>
          Los pagos de Productos y/o Servicios se realizan por medio de
          plataformas de pago de terceros. Kolyy no asume ninguna
          responsabilidad sobre la operatividad, funcionalidad, uso, pérdida o
          robo de datos bancarios que puedan resultar del uso de plataformas de
          pago.
          <br />
          <br />
          <b>4. EXENCIÓN DE RESPONSABILIDAD.</b>
          EXCEPTO EN CASO DE DOLO O CULPA GRAVE Y EN LA MEDIDA QUE LO PERMITA LA
          LEGISLACIÓN VIGENTE, KOLYY NO INCURRIRÁ EN NINGÚN CASO EN
          RESPONSABILIDAD POR (A) DAÑOS POR PÉRDIDA DE INGRESOS, PÉRDIDA DE
          BENEFICIOS, PÉRDIDA DE INGRESOS, PÉRDIDA DE INTERESES, AHORROS NO
          LLEVADOS A CABO, PÉRDIDA DE FONDOS DE COMERCIO, PÉRDIDA DE USO, GASTOS
          DE INACTIVIDAD, DAÑOS INDIRECTOS, ESPECIAL, INCIDENTAL, CONSECUENTE,
          DAÑOS RESULTANTES Y DAÑOS PATRIMONIALES, DAÑOS POR RECLAMACIONES DE
          TERCEROS O POR LA PÉRDIDA DE DATOS Y PROGRAMAS Y SU RESTAURACIÓN
          RELACIONADOS CON EL USO DEL PRODUCTO, EL SERVICIO, EL SITIO WEB, LA
          TIENDA EN LÍNEA Y/O LA APLICACIÓN KOLYY; NI TAMPOCO, (B) EN CASO DE
          DAÑOS PROVOCADOS POR EL USO INCORRECTO O INDEBIDO DE LOS PRODUCTOS O
          DEL SERVICIO POR EL CLIENTE O TERCEROS. EN CASO DE CULPA LEVE, LA
          RESPONSABILIDAD DE KOLYY EN CUALQUIER CASO ESTARÁ LIMITADA A DAÑOS
          PERSONALES. LA RESPONSABILIDAD MÁXIMA TOTAL DE KOLYY SE LIMITA AL
          PRECIO DEL PRODUCTO O A UNA ANUALIDAD DEL SERVICIO, EN LA MEDIDA QUE
          LO PERMITA LA LEGISLACIÓN VIGENTE.
        </p>
        <h6>X. Derecho a desistimiento</h6>
        <p>
          1. El Cliente, que tenga la consideración de “consumidor” según la Ley
          General para la Defensa de los Consumidores y Usuarios, puede desistir
          de un contrato celebrado fuera de las oficinas del empresario o de un
          contrato a distancia, en un plazo de catorce (14) días.
          <br />
          <br />
          2. El plazo de desistimiento comienza:
          <br />
          <br />
          a) en el caso de contratos sobre la entrega de Productos: el día en el
          que el Cliente o un tercero designado por este que no sea el
          transportista adquiere la posesión de los Productos. En caso de un
          contrato sobre varios Productos que se hayan pedido en un mismo pedido
          y se hayan entregado por separado, el plazo de desistimiento comenzará
          a contar a partir del día en el que el Cliente o un tercero designado
          por este que no sea el transportista adquiere la posesión del último
          Producto.
          <br />
          <br />
          b) en caso de adquisición del Servicio: el día de contratación del
          Servicio, entendiéndose como tal el momento en que el Usuario reciba
          la Aceptación del Pedido por Kolyy o el momento del Primer Uso del
          Servicio, lo que ocurra primero.
          <br />
          <br />
          3. Será suficiente cuando el Cliente haya enviado la declaración de
          desistimiento dentro del plazo, dirigiéndose a Kolyy mediante
          comunicación por escrito a la siguiente dirección electrónica:
          support@kolyy.com.
          <br />
          <br />
          Para ejercer el derecho de desistimiento, el Cliente podrá utilizar el
          modelo de formulario de desistimiento que figura al final de las
          presentes CGC.
          <br />
          <br />
          4. Una vez ejercitado el derecho de desistimiento:
          <br />
          <br />
          a) Devolución del Producto. El Cliente deberá devolver el Producto por
          correo o, a elección del Cliente, empresa de mensajería a c/ Ramon
          Escayola 20, Sant Cugat del Vallés, 08197, sin ninguna demora indebida
          y, en cualquier caso, a más tardar en el plazo de 14 días naturales a
          partir de la fecha en que haya comunicado su decisión de desistimiento
          del Contrato. El Cliente deberá devolver el Producto en su embalaje
          original para evitar daños en el transporte. Los gastos de devolución
          del Producto a la dirección indicada por el medio elegido por el
          Cliente correrán a cargo del Cliente.
          <br />
          <br />
          b) Interrupción del Servicio. Kolyy cancelará la Cuenta del Usuario y
          el acceso del Usuario al Servicio.
          <br />
          <br />
          5. Reembolso. Kolyy deberá reembolsar los pagos realizados por el
          Cliente uno a uno contra devolución de los Productos o prueba
          correspondiente de la devolución de los Productos, incluidos los
          gastos de entrega (con la excepción de los costes adicionales
          derivados de que el consumidor haya elegido un tipo de entrega
          diferente al de la entrega estándar más económica ofrecida por Kolyy).
          <br />
          <br />
          En el caso de direcciones de entrega fuera de la UE, el Cliente deberá
          reembolsar a Kolyy cualquier impuesto especial / derechos de aduana
          desencadenados por la devolución y cobrados a Kolyy.
          <br />
          <br />
          6. El Cliente será responsable de la disminución de valor del Producto
          resultante de una manipulación del mismo distinta a la necesaria para
          establecer su naturaleza, sus características o su funcionamiento. En
          caso de que el Producto estuviese dañado, Kolyy informara debidamente
          al Cliente y podrá retener el importe necesario para reparar el
          Producto de los pagos a reembolsar al cliente.
        </p>
        <h6>XI. Derechos de propiedad intelectual</h6>
        <p>
          1. El Usuario no obtendrá, por virtud del uso que haga del Producto,
          el Servicio, el Sitio Web, la Tienda en línea y/o la Aplicación Kolyy
          ningún derecho de propiedad sobre copyright, patentes, secretos
          comerciales, marcas comerciales ni ningún otro derecho de propiedad
          intelectual que pertenezca a Kolyy. No debe aplicar ingeniería
          inversa, descompilar ni desmontar el Producto ni el Servicio, excepto
          y solo en la medida que hacerlo sea obligatorio bajo la ley aplicable,
          sin perjuicio de esta limitación o de lo expuesto expresamente en las
          presentes CGC.
          <br />
          <br />
          2. Todos los derechos de autor y cualquier otro derecho de propiedad
          intelectual o industrial o cualquier otro derecho de cualquier índole
          relativo los contenidos o de los aspectos del Sitio web, la Tienda en
          línea, la Aplicación Kolyy y/o de los Productos y Servicios
          corresponde a Kolyy (“Derechos de Propiedad Intelectual”). Dichos
          Derechos de Propiedad Intelectual están protegidos por las leyes en
          materia de derechos de propiedad intelectual y por los tratados
          internacionales vigentes en España y en el mundo y los derechos sobre
          los mismos quedan reservados expresamente. Queda prohibido cualquier
          uso de los contenidos del Sitio web, Aplicación Kolyy, los Productos y
          Servicios que no esté expresamente autorizado en las presentes CGC,
          las Condiciones de Uso del Sitio Web o el EULA de la Aplicación Kolyy.
          <br />
          <br />
          3. El Usuario reconoce que Kolyy es el legítimo titular de las marcas,
          nombres comerciales y demás denominaciones y/o signos distintivos
          sobre el Sitio web, la Tienda en línea, la Aplicación Kolyy, los
          Productos, Servicios y sus elementos accesorios (en adelante, «Marcas
          Kolyy »). El Cliente no registrará o usará ningún dominio en Internet
          que contenga cualquier Marca Kolyy total o parcialmente o cualquier
          otro nombre que pueda inducir a confusión por su similitud o que de
          cualquier otro modo pudiere lesionar los derechos de propiedad
          industrial de Kolyy. El Usuario no debe utilizar etiquetas meta ni
          ningún otro tipo de texto oculto que utilice algún nombre, marca
          comercial o nombre de producto o de servicio de Kolyy sin el previo
          consentimiento por escrito de Kolyy. Además, el aspecto y el estilo
          del Producto, el Servicio, la Tienda en línea, el Sitio Web y las
          Aplicaciones Kolyy (incluyendo todos los encabezados de página,
          gráficos personalizados, iconos de botón y secuencias de comandos) son
          la marca del servicio, la marca comercial y la imagen de marca de
          Kolyy y no deben copiarse, imitarse o utilizarse (en su totalidad o en
          parte) sin el previo permiso por escrito de Kolyy.
          <br />
          <br />
          4. El Usuario no podrá, directa o indirectamente, copiar,
          desencriptar, escanear electrónicamente, descompilar o derivar código
          fuente de ninguna propiedad intelectual y/o industrial titularidad de
          Kolyy al que tenga acceso en virtud de los Productos y/o Servicios
          Kolyy, o realizar ingeniería inversa en el diseño y función de la
          referida propiedad intelectual y/o industrial. Asimismo, el Usuario se
          obliga a informar de manera rápida y eficaz de toda infracción o
          fundado temor de infracción por parte de los Usuarios o de terceros
          que pudiera afectar a los legítimos intereses de Kolyy del que el
          Usuario pudiera tener conocimiento.
          <br />
          <br />
          5. Sin perjuicio de lo dispuesto, no se podrá reproducir ni archivar
          ningún extremo de los contenidos del Sitio web, la Tienda en línea, la
          Aplicación Kolyy, de los Productos y Servicios en otro sitio web ni
          tampoco se podrá introducir en otro sistema de archivo y búsqueda de
          información o de prestación de servicios sin el consentimiento previo
          y por escrito de Kolyy.
          <br />
          <br />
          6. Toda utilización del Sitio Web, Tienda en línea, Aplicación Kolyy
          y/o los Productos y Servicio que se realice sin autorización previa
          por escrito y que no estuviera autorizada en las presentes CGC queda
          expresamente prohibida. Dicha utilización no autorizada podría
          asimismo contravenir las leyes aplicables, a título ejemplificativo,
          leyes en materia de derechos de autor, sobre la protección de la marca
          y otras normativas en materia de comunicación o de protección de datos
          con carácter personal. Nos reservamos el derecho a suspender aquellas
          cuentas de Usuarios que consideremos que hayan vulnerado la ley o los
          reglamentos aplicables.
          <br />
          <br />
          7. Ningún extremo de lo dispuesto expresamente en las presentes CGC
          puede interpretarse como concesión de licencias sobre derechos de
          propiedad intelectual, ni de forma implícita ni como hecho conclusivo.
        </p>
        <h6>XII. Modificación de las Condiciones Generales</h6>
        <p>
          1. Kolyy se reserva el derecho a modificar o actualizar, total o
          parcialmente, en cualquier momento estas CGC o cualquier parte,
          versión o función del Servicio y promociones en cualquier momento
          («Modificaciones») para (a) reflejar cambios en la tecnología Kolyy u
          otros sistemas de Kolyy o de terceros; (b) reflejar ajustes
          introducidos al Servicio y/o implementar nuevas ofertas de Servicio;
          (c) reflejar cambios en las condiciones de entrega, precios y otras
          circunstancias relevantes de los Productos y/o Servicios; (d) reflejar
          cambios en los requisitos normativos o cambios legislativos; yo (e)
          cualquier otra razón valida
          <br />
          <br />
          2. Kolyy notificará al Usuario a través del Sitio web, Tienda en línea
          y de la Aplicación Kolyy dónde aparecerá un aviso que será necesario
          aceptarlo para continuar con el uso del mismo. Si el Usuario acepta la
          modificaciones introducidas a las CGC, se considerarán aceptadas.
          Kolyy también puede enviarle una notificación de la Modificación a la
          dirección de correo electrónico facilitada al momento de contratar el
          Servicio.
          <br />
          <br />
          3. La Modificación entrará en vigor a los treinta (30) días de la
          publicación o notificación al Usuario de conformidad con lo
          establecido en el apartado anterior.
          <br />
          <br />
          4. El uso continuado del Servicio después de que la modificación entre
          en vigor, indicará la aceptación por su parte de la Modificación.
          <br />
          <br />
          5. En caso de que el Usuario quiera oponerse al cambio de Condiciones
          Generales, deberá cancelar el uso del Servicio de conformidad con el
          apartado VI.7.a) de las presentes CGC.
          <br />
          <br />
          6. La última versión de las CGC estará siempre disponible en el Sitio
          web, la Tienda en línea y Aplicación Kolyy en un sitio visible y
          libremente accesible. Por favor, asegúrese de comprobar las
          actualizaciones de las CGC cada vez que utilice el Sitio web, Tienda
          en línea y/o la Aplicación Kolyy. En cualquier caso, la aceptación de
          las CGC será un paso previo e indispensable a la contratación y uso de
          los Productos y/o Servicio.
        </p>
        <h6>XIII. Cesión de derechos y obligaciones de subcontratación.</h6>
        <p>
          1. El Usuario no podrá ceder y/o transmitir los derechos y
          obligaciones relativos a estas CGC, sin la previa autorización expresa
          y por escrito por parte de Kolyy.
          <br />
          <br />
          2. Kolyy se reserva el derecho a subcontratar a otras personas físicas
          o jurídicas con el fin de poder llevar a cabo las obligaciones
          derivadas de las presentes CGC, sin necesidad de autorización previa
          del Cliente.
        </p>
        <h6>XIV. Notificaciones</h6>
        <p>
          1. Todas las comunicaciones entre Kolyy y el Usuario relativas a estas
          CGC se realizarán por escrito y de conformidad con los procedimientos
          de comunicación establecidos en estas CGC para cada caso en
          particular.
          <br />
          <br />
          2. Para el resto de los supuestos que no estén expresamente regulados
          en estas CGC, las comunicaciones que el Usuario pretenda remitir a
          Kolyy se dirigirán a la dirección de correo electrónico
          support@kolyy.com, realizándose siempre por escrito y mediante un
          sistema que permita acreditar el contenido y la recepción por parte de
          Kolyy de la correspondiente comunicación.
          <br />
          <br />
          3. Es responsabilidad del Cliente o Usuario notificar a Kolyy de los
          cambios en su dirección particular o comercial mientras el contrato
          esté vigente. Si se omite la notificación, las declaraciones también
          se considerarán como recibidas si se envían a la última dirección
          conocida.
        </p>
        <h6>XV. Cumplimiento de la ley y leyes de exportación</h6>
        <p>
          1. El Cliente acepta cumplir toda la legislación y normativa aplicable
          vigente. Sin perjuicio de lo anterior, es responsable del cumplimiento
          de la legislación y normativas de control de exportación de España y
          otros países en los que se aplica («Leyes de exportación»). Cumplirá
          todas las leyes de exportación para garantizar que los Productos y el
          Servicio, así como cualquier material relacionado, incluidos, entre
          otros, los datos técnicos, no (1) se exporten o reexporten, directa o
          indirectamente, infringiendo las leyes de exportación, o (2) se
          utilicen para un propósito prohibido por las leyes de exportación.
          <br />
          <br />
          2. Sin perjuicio de lo anterior, acepta que el Producto y los
          Servicios no se pueden (infringiendo las leyes de exportación u otra
          legislación o normativa aplicable) exportar o reexportar (i) a ningún
          país sometido al embargo por parte de España y la UE, o (ii) a ninguna
          persona que se encuentre en la lista de personas especialmente
          designadas por parte de España y la UE. Declara y garantiza que no es
          residente de ningún país de este tipo y que no se encuentra en ninguna
          de estas listas.
        </p>
        <h6>XVI. Nulidad parcial</h6>
        <p>
          1. Si una disposición de estas condiciones contractuales no tuviera
          efecto jurídico, fuera inválida y/o nula, ahora o en el futuro, esto
          no afectará a la eficacia jurídica o a la validez del resto de
          disposiciones. En este caso, las partes contratantes se comprometen a
          sustituir la disposición sin efecto jurídico, inválida y/o nula (en
          dicho momento o en el futuro) por una disposición que tenga efecto
          jurídico y sea válida y cuyo impacto económico se corresponda con la
          disposición sustituida, siempre que sea posible y así lo permita la
          ley.
        </p>
        <h6>XVII. Legislación y jurisdicción aplicable</h6>
        <p>
          1. En lo que no fuera contrario a norma imperativa de obligada
          aplicación Las presentes CGC se rigen por la ley española.
          <br />
          <br />
          2. Para la resolución de cualquier controversia judicial derivada de
          la interpretación y/o cumplimiento de las presentes CGC, las partes,
          con renuncia a cualquier otro fuero general o especial que pudiera
          corresponderles, se someten a los Juzgados y Tribunales de Barcelona.
          No obstante, de tener alguna de las partes la consideración de
          “Consumidor”, de conformidad a lo establecido en la Ley General para
          la Defensa de los Consumidores y Usuarios, no le será de aplicación la
          cláusula de sumisión antes expuesta, sino que el fuero aplicable será
          el que en cada momento le corresponda en función de dicha
          consideración.
        </p>
        <h6>XVIII. VENTA DE PRODUCTOS EN PREVENTA</h6>
        <p>
          1. Kolyy podrá ofrecer en la Tienda en línea Productos y/o Servicios
          que todavía no estén disponibles (“Preventa”). En dicho caso, Kolyy
          informará adecuadamente al Cliente de que los Productos están en
          Preventa y proporcionará una fecha estimada de puesta a disposición.
          <br />
          <br />
          EL CLIENTE RECONOCE Y ACEPTA QUE ESTÁ RESERVANDO UN PRODUCTO EN
          PREVENTA Y QUE EL PLAZO DE ENTREGA ES ESTIMADO Y PUEDE MODIFICARSE.
          <br />
          <br />
          2. La venta de Productos y Servicios en Preventa se regulará, en la
          medida de lo aplicable, por las presentes CGC y en particular por el
          presente apartado. En caso de que hubiese cualquier contradicción, el
          presente apartado prevalecerá por encima de las demás secciones en lo
          relativo a Productos y/o Servicios en Preventa.
          <br />
          <br />
          3. El pedido de Preventa se realiza en los siguientes pasos:
          <br />
          <br />
          a) Selección del Producto y Servicio deseado añadiendo a la cesta de
          compra haciendo clic sobre el icono correspondiente;
          <br />
          <br />
          b) Para realizar el pedido se requerirá la introducción de la
          dirección de envío/datos de contacto (nombre, apellido(s), calle y
          número, código postal, ciudad, país, dirección e-mail, número de
          teléfono para que el transportista pueda ponerse en contacto si fuera
          necesario), así como una dirección de facturación diferente en su
          caso; elección de la forma de envío y del método de pago. Los datos
          personales proporcionados por el Cliente serán tratados de acuerdo con
          lo establecido en nuestra Política de Protección de datos
          (https://kolyy.com/es/politica-de-privacidad/);
          <br />
          <br />
          c) Antes de finalizar el pedido se presentará al Cliente un resumen
          del mismo identificando el Producto y/o Servicio añadido en la cesta
          de compra, su precio total (transporte e impuestos incluidos) con cada
          uno de los conceptos desglosados y los datos de envío del pedido. La
          fecha de entrega que se indique al realizar el pedido es estimada y
          depende de la disponibilidad del Producto por Kolyy. Tras la
          verificación de los datos del pedido en la cesta de la compra, el
          Cliente deberá confirmar el pedido haciendo clic en el botón
          “confirmar pago” (“Confirmación de Pedido”)
          <br />
          <br />
          d) En caso de que antes de la Confirmación de Pedido el Cliente
          detectase un error en su pedido, podrá modificarlo haciendo clic en el
          apartado “Editar” o “Eliminar”. En caso de detectar un error en su
          pedido una vez finalizado el proceso de Confirmación de Pedido, el
          Cliente deberá ponerse en contacto con Kolyy a través de
          support@kolyy.com ;
          <br />
          <br />
          e) Tras la Confirmación de Pedido, el Cliente será reconducido a una
          plataforma de pago para efectuar el pago según lo previsto en la
          sección VII «Precios, gastos de envío y formas de pago».
          <br />
          <br />
          f) Al confirmar el pedido, el Cliente reconoce y acepta la información
          precontractual disponible en la Página web o en la Aplicación Kolyy y
          estas CGC como determinantes para la relación contractual con Kolyy.
          <br />
          <br />
          g) Kolyy enviará al Cliente acuse de recepción de la Confirmación del
          Pedido. El acuse de la recepción de la Confirmación del Pedido se
          considerará una reserva del Producto para cuando esté disponible y no
          supondrá la aceptación del pedido por parte de Kolyy.
          <br />
          <br />
          4. Aceptación del Pedido y entrega. Una vez el Producto y/o Servicio
          en Preventa esté disponible, se enviará Aceptación del Pedido al
          Cliente, informándole del plazo de entrega del Producto y/o Servicio
          que, en cualquier caso, no podrá ser superior a 30 días desde la
          Aceptación del Pedido. El contrato se entiende perfeccionado por medio
          de la Aceptación del Pedido.
          <br />
          <br />
          5. Retraso. Kolyy hará esfuerzos razonables para entregar el Producto
          en la fecha estimada de puesta a disposición. Sin embargo, en caso de
          que, por circunstancias fuera del control de Kolyy, el Producto en
          Preventa no esté disponible en la fecha estimada de puesta a
          disposición, Kolyy informará al Cliente en cuanto sea conocedora de
          dicho retraso, dando la opción al Cliente de optar entre (i) desistir
          de la compra en preventa con devolución de importes pagados; o, (ii)
          esperar hasta la nueva fecha de entrega estimada facilitada por Kolyy.
          <br />
          <br />
          En caso de optar por desistir de la compra en preventa, el Cliente
          deberá enviar escrito a Kolyy y Kolyy procederá a devolver las
          cantidades previamente abonadas por el Cliente en un plazo razonable.
          El Cliente no tendrá derecho a reclamar ningún tipo de indemnización
          por el retraso en Productos y/o Servicios de Preventa.
          <br />
          <br />
          En caso de no recibir respuesta, se entenderá que el Cliente acepta la
          nueva fecha de entrega estimada.
          <br />
          <br />
          6. En caso de pedidos mixtos integrados por Productos correspondientes
          al proceso de compra habitual y a Productos en preventa, Kolyy se
          reserva el derecho de hacer entregas parciales y por separado de los
          Productos y en plazos distinto.
        </p>

        
        <br /><br />
        <p>
          <b>Descarga el pdf del </b>
          <b>
            <a
              style={{ color: "#30AAAA" }}
              rel="noreferrer"
              target="_blank"
              href="https://drive.google.com/file/d/1mxzJxnNOPcPaAeEdMAwikOjOeBzUZeZE/view?usp=sharing"
              download
            >
              {' '}formulario de desistimiento
            </a>.
          </b>
        </p>
      </div>
    </Layout>
  );
};

export default Terms;
